import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUrlService } from '../Infrastructure/apiurl.service';
import { MyOrganizationModel } from 'src/app/Models/MyOrganizationModel';
import { IdRequestModel } from 'src/app/Models/IdRequestModel';
import { CommonResponse } from 'src/app/Models/Infrastructure/CommonResponse';



@Injectable({
  providedIn: 'root'
})
export class MyOrganizationService {

  constructor(private http: HttpClient, private apiUrls: ApiUrlService) { }
  private MyOrganizationUrl = this.apiUrls.ConfigurationApi + "/myorganization";

  getMyOrganization(): Observable<{data:MyOrganizationModel[]}> {
    return this.http.get<{data:MyOrganizationModel[]}>(this.MyOrganizationUrl + "/GetMyOrganization");
  }

  updateOrganization(myOrganization: MyOrganizationModel): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.MyOrganizationUrl + "/UpdateMyOrganization", myOrganization);
  }

  getById(idRequestModel: IdRequestModel): Observable<MyOrganizationModel> {
    return this.http.post<MyOrganizationModel>(this.MyOrganizationUrl + "/GetMyOrganizationById",  idRequestModel);
  }
}
