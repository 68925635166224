import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetsSearchModel } from 'src/app/Models/AssetsSearchModel';
import { DropDownModel } from 'src/app/Models/DropDownModel';

import { IdRequestModel } from 'src/app/Models/IdRequestModel';
import { CommonResponse } from 'src/app/Models/Infrastructure/CommonResponse';
import { ResultModel } from 'src/app/Models/Infrastructure/ResultModel';
import { PowerUnitModel } from 'src/app/Models/PowerUnitModel';
import { PowerUnitsList } from 'src/app/Models/PowerUnitsListModel';
import { SearchModel } from 'src/app/Models/SearchModel';
import { ApiUrlService } from '../Infrastructure/apiurl.service';

@Injectable({
  providedIn: 'root'
})
export class PowerUnitService {

  constructor(private http: HttpClient, private apiUrls: ApiUrlService) { }

  private PowerUnitUrl = this.apiUrls.AssetsAndMaintanceApi + "/PowerUnit";

  getPowerUnits(searchModel: SearchModel): Observable<{ data: PowerUnitModel[], total: number }> {
    return this.http.post<{ data: PowerUnitModel[], total: number }>(this.PowerUnitUrl + "/GetPowerUnits", searchModel);
  }
  createPowerUnit(powerUnit: PowerUnitModel): Observable<ResultModel<PowerUnitModel>> {
    return this.http.post<ResultModel<PowerUnitModel>>(this.PowerUnitUrl + "/CreatePowerUnit", powerUnit);
  }
  updatePowerUnit(powerUnit: PowerUnitModel): Observable<ResultModel<PowerUnitModel>> {
    return this.http.post<ResultModel<PowerUnitModel>>(this.PowerUnitUrl + "/UpdatePowerUnit", powerUnit);
  }
  getPowerUnitById(idRequestModel: IdRequestModel): Observable<PowerUnitModel> {
    return this.http.post<PowerUnitModel>(this.PowerUnitUrl + "/GetPowerUnitById/", idRequestModel);
  }
  getPowerUnitName(idRequestModel: IdRequestModel): Observable<ResultModel<DropDownModel>> {
    return this.http.post<ResultModel<DropDownModel>>(this.PowerUnitUrl + "/GetPowerUnitName", idRequestModel);
  }
  getPowerUnitsCarrier(assetsSearchModel: AssetsSearchModel): Observable<{ data: DropDownModel[], total: number }> {
    return this.http.post<{ data: DropDownModel[], total: number }>(this.PowerUnitUrl + "/GetPowerUnitsCarrier", assetsSearchModel);
  }
  getPowerUnitsMyCompany(searchModel: SearchModel): Observable<{ data: DropDownModel[], total: number }> {
    return this.http.post<{ data: DropDownModel[], total: number }>(this.PowerUnitUrl + "/GetPowerUnitsMyCompany", searchModel);
  }
  getDropDownPowerUnits(): Observable<{ data: DropDownModel[] }> {
    return this.http.get<{ data: DropDownModel[] }>(this.PowerUnitUrl + "/DropDownPowerUnits");
  }
  getPowerUnitsList(listPowerUnits:PowerUnitsList):Observable<{data:DropDownModel[], total:number}>{
    return this.http.post<{data:DropDownModel[], total:number}>(this.PowerUnitUrl+"/GetPowerUnitsList", listPowerUnits)
  }
  deletePowerunit(idRequest:IdRequestModel):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.PowerUnitUrl+"/DeletePowerUnit", idRequest)
  }
} 
