import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetsSearchModel } from 'src/app/Models/AssetsSearchModel';
import { DropDownModel } from 'src/app/Models/DropDownModel';
import { IdRequestModel } from 'src/app/Models/IdRequestModel';
import { CommonResponse } from 'src/app/Models/Infrastructure/CommonResponse';
import { ResultModel } from 'src/app/Models/Infrastructure/ResultModel';
import { RefKeyRequestModel } from 'src/app/Models/RefKeyRequestModel';
import { SearchModel } from 'src/app/Models/SearchModel';
import { TrailerList } from 'src/app/Models/TrailerListModel';
import { TrailerModel } from 'src/app/Models/TrailerModel';
import { ApiUrlService } from '../Infrastructure/apiurl.service';

@Injectable({
  providedIn: 'root'
})
export class TrailerService {

  constructor(private http: HttpClient, private apiUrls: ApiUrlService) { }

  private TrailerUrl = this.apiUrls.AssetsAndMaintanceApi + "/Trailer";

  getTrailers(searchModel: SearchModel): Observable<{ data: TrailerModel[], total: number }> {
    return this.http.post<{ data: TrailerModel[], total: number }>(this.TrailerUrl + "/GetTrailers", searchModel);
  }

  createTrailer(trailer: TrailerModel): Observable<ResultModel<TrailerModel>> {
    return this.http.post<ResultModel<TrailerModel>>(this.TrailerUrl + "/CreateTrailer", trailer);
  }
  updateTrailer(trailer: TrailerModel): Observable<ResultModel<TrailerModel>> {
    return this.http.post<ResultModel<TrailerModel>>(this.TrailerUrl + "/UpdateTrailer", trailer);
  }
  getByRefKey(refKeyRequestModel: RefKeyRequestModel): Observable<TrailerModel> {
    return this.http.post<TrailerModel>(this.TrailerUrl + "/GetTrailerByRefKey", refKeyRequestModel);
  }
  getTrailerName(idRequestModel: IdRequestModel): Observable<ResultModel<DropDownModel>> {
    return this.http.post<ResultModel<DropDownModel>>(this.TrailerUrl + '/GetTrailerName', idRequestModel)
  }
  getTrailersCarrier(assetsSearchRequest: AssetsSearchModel): Observable<{ data: DropDownModel[], total: number }> {
    return this.http.post<{ data: DropDownModel[], total: number }>(this.TrailerUrl + '/GetTrailersCarrier', assetsSearchRequest)
  }
  getTrailersMyCompany(searchModel: SearchModel): Observable<{ data: DropDownModel[], total: number }> {
    return this.http.post<{ data: DropDownModel[], total: number }>(this.TrailerUrl + '/GetTrailersCarrier', searchModel)
  }
  getDropDownTrailers(): Observable<{ data: DropDownModel[] }> {
    return this.http.get<{ data: DropDownModel[] }>(this.TrailerUrl + "/DropDownTrailers");
  }
  getTrailersList(trailerList:TrailerList):Observable<{data:DropDownModel[], total:number}>{
    return this.http.post<{data:DropDownModel[], total:number}>(this.TrailerUrl+"/GetTrailersList",trailerList)
  }
  deleteTrailer(idRequest:IdRequestModel):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.TrailerUrl+"/DeleteTrailer", idRequest)
  }
}
