import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from 'src/app/Models/ContactModel';
import { IdRequestModel } from 'src/app/Models/IdRequestModel';
import { CommonResponse } from 'src/app/Models/Infrastructure/CommonResponse';
import { RefKeyRequestModel } from 'src/app/Models/RefKeyRequestModel';
import { SearchModel } from 'src/app/Models/SearchModel';


import { ApiUrlService } from '../Infrastructure/apiurl.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient, private apiUrls: ApiUrlService) { }

  private ContactUrl = this.apiUrls.ConfigurationApi + "/contact";

  getContacts(searchModel:SearchModel): Observable<{ data: ContactModel[], total: number }> {
    return this.http.post<{ data: ContactModel[], total: number }>(this.ContactUrl + "/GetContacts", searchModel);
  }

  createContact(contactItem: ContactModel): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.ContactUrl + "/CreateContact", contactItem);
  }

  updateContact(contactItem: ContactModel): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(this.ContactUrl + "/UpdateContact", contactItem);
  }

  getByRefKey(refKey: RefKeyRequestModel): Observable<ContactModel> {
    return this.http.post<ContactModel>(this.ContactUrl + "/GetContactByRefKey/", refKey);
  }
  getContactsByBAId(idRequest:IdRequestModel):Observable<{data:ContactModel[], total:number}>{
    return this.http.post<{data:ContactModel[], total:number}>(this.ContactUrl+"/GetContactsByBAId", idRequest)
  }
  deleteContact(idRequest:IdRequestModel):Observable<CommonResponse>{
    return this.http.post<CommonResponse>(this.ContactUrl+'/DeleteContact',idRequest)
  }
}
